import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './AuthProvider';
import { Auth0Provider } from '@auth0/auth0-react';
import SurveyManager from './components/SurveyManager';
import About from './components/About';
import SurveyHistory from './components/SurveyHistory';
import Result from './components/Result';
import TnCModal from './components/TnCModal';
import TnC from './components/TnC';
import SurveyList from './components/list_views/SurveyList';
import VideoModal from './components/VideoModal';
// import AudienceList from './components/AudienceList';
// import SegmentList from './components/SegmentList';
// import QuestionsList from './components/QuestionsList';
import * as Sentry from "@sentry/react";

const SurveyWrapper = ({ context }) => {
  const { existingSurveyId } = useParams();
  return <SurveyManager existingSurveyId={existingSurveyId ? existingSurveyId : null} currentContext={context} />;
};

Sentry.init({
  dsn: "https://1e0f91f8f88434c8d586e57012feb4f5@o4507748708581376.ingest.us.sentry.io/4507748712579072",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions

  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}

    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "read:current_user read:current_user_metadata"
    }}
    cacheLocation="localstorage"
  >
    <AuthProvider>
      <Router>
        <React.StrictMode>
          <App />
        </React.StrictMode>
        <Routes>
          <Route path="/" element={<SurveyHistory />} />
          <Route path="/create-survey/" element={<SurveyWrapper context={'create'}/>} />
          <Route path="/copy-survey/:existingSurveyId" element={<SurveyWrapper context={'copy'}/>} />
          <Route path="/edit-survey/:existingSurveyId" element={<SurveyWrapper context={'edit'}/>} />
          <Route path="/about" element={<About />} />
          <Route path="/surveyhistory" element={<SurveyHistory />} />
          <Route path="/result/:surveyRunId" element={<Result />} />
          <Route path="/terms-and-conditions" element={<TnC />} />
          <Route path="/accept-tnc" element={<TnCModal />} />
          <Route path="/surveys" element={<SurveyList />} />
          <Route path="/welcome" element={<VideoModal />} />
          {/* <Route path="/audiences" element={<AudienceList />} />
          <Route path="/segments" element={<SegmentList />} />
          <Route path="/questions" element={<QuestionList />} /> */}
        </Routes>
      </Router>
    </AuthProvider>
  </Auth0Provider >
);
reportWebVitals();
