import Sidebar from '../Sidebar';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useApi } from '../../AuthProvider';
import Header from '../ui/Header';
import Footer from '../ui/Footer';

import DynamicTable from '../DynamicTable';

const SurveyList = () => {
    const [surveyList, setSurveyList] = useState([]);
    const api = useApi();
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            const response = await api.get(`/surveys/`);
            console.log('Survey list:', response.data);
            setSurveyList(response.data);
            // DEBUG: prints the details for last X surveys in the console
            for (let i = 0; i < 5; i++) {
                const debugResponse = await api.get(`/surveys/${response.data[i].survey_id}`);
                console.log('Survey details:', debugResponse.data);
            }
        }
        if (api) fetchData();
    }, [api]);

    // actions represent the buttons and their functions showing in the table's "Actions" col
    const surveyActions = [
        {
            label: 'Details',
            callback: (rowData) => {
                console.log('displaying survey details:', rowData);
                const surveyId = rowData.row.survey_id;
                navigate(`/edit-survey/${surveyId}?display=preview`);
            }
        },
        {
            label: 'Edit',
            callback: (rowData) => {
                console.log('editing survey:', rowData);
                const surveyId = rowData.row.survey_id;
                navigate(`/edit-survey/${surveyId}`);
            }
        },
        {
            label: 'Copy',
            callback: (rowData) => {
                console.log('copying survey:', rowData);
                const surveyId = rowData.row.survey_id;
                navigate(`/copy-survey/${surveyId}`);
            }
        },
        {
            label: 'Delete',
            callback: async (params) => {
                const response = await api.delete(`/surveys/${params.row.survey_id}`);
                console.log('deleting survey:', params.row.survey_id);
                console.log(surveyList);
                console.log(surveyList.filter(survey => survey.survey_id !== params.row.survey_id));
                if (response && response.status !== 200) {
                    console.log('error deleting survey');
                } else {
                    console.log('survey deleted');
                    console.log(response);
                }
                setSurveyList(surveyList.filter(survey => survey.survey_id !== params.row.survey_id));
            },
        }
    ];

    return (
        <div className="dashboard">
            <Header />
            <div className='dashboard-content' style={{ width: 'auto' }}>
                <h1>My Surveys</h1>
                <DynamicTable
                    tableData={surveyList}
                    updateTableData={setSurveyList}
                    visibleColumns={['name', 'created_at', 'audience_name', 'total_segments', 'total_questions', 'total_runs']}
                    columnLabels={['Name', 'Date', 'Audience', 'Segments', 'Questions', 'Runs', 'Actions']}
                    selectOnClick={false}
                    selectable={false}
                    draggable={false}
                    itemName="survey"
                    actions={surveyActions}
                />
            </div>
            <Footer />
        </div>
    );
};

export default SurveyList;
