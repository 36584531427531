import Sidebar from './Sidebar'
import StackedColumnChart from './StackedColumnChart';
import Loading from './ui/Loading';
import BarChart from './BarChart';
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import download from '../assets/download-icon.svg';
import { Button } from '@mui/material';
import { useApi } from '../AuthProvider';
import { useParams } from 'react-router-dom';
import Markdown from 'react-markdown'
import ContentCopy from '@mui/icons-material/ContentCopy';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Grid from '@mui/material/Unstable_Grid2';
import Header from './ui/Header';
import Footer from './ui/Footer';

const Result = () => {
  const [surveyRunResults, setSurveyRunResults] = useState(null);
  const params = useParams();
  const api = useApi();
  const [copySnackbarOpen, setCopySnackbarOpenOpen] = useState(false);

  useEffect(() => {
    async function fetchData() {
      var surveyRunsResponse = await api.get(`/surveys/runs/${params.surveyRunId}`);

      setSurveyRunResults(surveyRunsResponse.data);
    }
    if (surveyRunResults === null && api) {
      fetchData();
    }
  }, [api]);

  const downloadSummary = (surveyRunId, summary) => {
    // Create blob link to download
    const url = window.URL.createObjectURL(
      new Blob([summary], {
        type: 'text/markdown',
        encoding: 'UTF-8'
      })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `${surveyRunId}.md`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  }

  const downloadFile = async (surveyRunId) => {
    var downloadResponse = await api.get(`/surveys/runs/${surveyRunId}/download/xlsx`, { responseType: "blob" });
    if (downloadResponse) {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([downloadResponse.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${surveyRunResults.name}.xlsx`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
  }

  const QuestionResultDetails = ({ question, answers }) => {
    const [numShow, setNumShow] = useState(5);
    const [textShow, setTextShow] = useState('Show more');
    if (question.question_type === 'Short Response' || question.question_type === 'Long Response') {
      return (
        <div>
          {/* if length answers is less than 5 */}
          {
            (answers.length <= 5)
            &&
            answers.map((answer, index) => (
              <div key={index}>
                <p>{answer.answer}</p>
              </div>
            ))
          }
          {/* if length answers is greater than 5 */}
          {
            (answers.length > 5)
            &&
            <div>
              {answers.slice(0, numShow).map((answer, index) => (
                <div key={index}>
                  <p>{answer.answer}</p>
                </div>
              ))}
              <Button
                onClick={() => {
                  if (numShow === 5) {
                    setNumShow(answers.length);
                    setTextShow('Show less');
                  }
                  else {
                    setNumShow(5);
                    setTextShow('Show more');
                  }
                }}
              >{textShow}</Button>
            </div>
          }
        </div>
      );
    } else if (question.question_type === 'Multiple Choice' || question.question_type === 'Single Choice') {
      let answerCounts = {};

      answers.forEach(answer => {
        if (answerCounts[answer.answer.trim()] === undefined) {
          answerCounts[answer.answer.trim()] = 1;
        } else {
          answerCounts[answer.answer.trim()] += 1;
        }
      });

      // sort answerCounts to display in the original order of the options
      answerCounts = Object.keys(answerCounts).sort((a, b) => question.options.indexOf(a) - question.options.indexOf(b)).reduce((obj, key) => {
        obj[key] = answerCounts[key];
        return obj;
      }, {});

      // NOTE: this behavior could/should become configurable in the future.
      // currently, we do not correlate the responses to the provided answers,
      // we just chart the given responses. This means that if the question is
      // multiple choice, and options are ['a', 'b', 'c'], but the responses contain
      // a combo response such as 'a, b', we will chart 'a, b' as a separate response.
      const labels = Object.keys(answerCounts);

      const totalAnswerCounts = Object.values(answerCounts).reduce((a, b) => a + b, 0);

      const datasets = {
        label: 'Responses',
        data: labels.map(label => (answerCounts[label] / totalAnswerCounts) * 100 || 0),
      }

      return (
        <BarChart data={Object.entries(answerCounts)} datasets={datasets} labels={labels} />
      );
    } else if (question.question_type === 'Numeric Rating') {

      const answerCounts = {};

      answers.forEach(answer => {
        if (answerCounts[answer.answer] === undefined) {
          answerCounts[answer.answer] = 1;
        } else {
          answerCounts[answer.answer] += 1;
        }
      });

      // instead of hardcoding the labels, we want to make sure that the
      // scale starts at min = 1, and max = 5 unless there is a response
      // higher than 5. then we fill in the blank labels in between min-max
      // that had no response for that value
      const labels = Object.keys(answerCounts);
      labels.forEach((label, index) => {
        labels[index] = parseInt(label);
      });
      // always begin at 1
      labels.unshift(1);
      // if the max isn't 5, make it 5
      Math.max(...labels) < 5 && labels.push(5);
      // between 1 and max, fill in any missing values
      for (let i = 1; i < Math.max(...labels); i++) {
        if (!labels.includes(`${i}`)) {
          labels.push(`${i}`);
        }
      }

      const totalAnswerCounts = Object.values(answerCounts).reduce((a, b) => a + b, 0);

      const datasets = {
        label: 'Responses',
        data: labels.map(label => (answerCounts[label] / totalAnswerCounts) * 100 || 0),
      }

      return (
        <BarChart data={Object.entries(answerCounts)} datasets={datasets} labels={labels} />
      );
    }
  }

  const QuestionAudienceSegmentResults = ({ question, segment, answers }) => {
    // this goes through the answers every question, so it'll start going REALLY slow if there are a lot of answers
    const segmentAnswers = answers.filter(answer => answer.question_id == question.question_id && answer.audience_segment_id == segment.audience_segment_id);
    return (
      <div>
        <h3>{segment.segment_name}</h3>
        <QuestionResultDetails question={question} answers={segmentAnswers} />
      </div>
    );
  }

  const QuestionResult = ({ questionNo, question, segments, answers }) => {
    return (
      <div>
        <h4>{questionNo}. "{question.question_text}"</h4>
        {segments.map((segment, index) => (
          <QuestionAudienceSegmentResults key={index} question={question} segment={segment} answers={answers} />
        ))}
      </div>
    );
  }

  const SurveySummary = ({ summary }) => {
    // If the summary is a string (the old way), render it as markdown
    if (typeof summary === 'string') {
      return (
        <Markdown className={'summary-block'}>{summary}</Markdown>
      );

    // If the summary is an object (the new way), render it as HTML
    } else if (typeof summary === 'object') {
      return (
        <div id="synthesis-div">
          {Object.entries(summary).map(([key, value]) => (
            <div className={'summary-block'} dangerouslySetInnerHTML={{ __html: value }}></div>
          ))}
        </div>
    )
    
    // If the summary is neither a string nor an object, render it as is
    } else {
      return (
        {summary}
      );
    }
  }

  const QuestionResults = ({ questions, segments, answers }) => {
    return (
      <>
        {questions.map((question, index) => (
          <QuestionResult key={index} questionNo={index + 1} question={question} segments={segments} answers={answers} />
        ))}
      </>
    );
  }

  const copySummary = () => {
    let summary = surveyRunResults.summary;

    if (typeof summary === 'object') {
      // combine all the summary sections into one string
      summary = Object.values(summary).join('\n\n');
      const regex = /(<([^>]+)>)/gi;
      summary = summary.replace(regex, "");
    } else if (typeof summary === 'string') {
      // strip markdown
      summary = summary.replace(/(#)+ /g, '')// headers
        .replace(/\*/g, '')// bold
        .replace(/- /g, '\n');// lists
    }

    navigator.clipboard.writeText(summary);

    setCopySnackbarOpenOpen(true);
  }

  if (surveyRunResults === null) {
    return <Loading />;
  }
  console.log('rendering results...');
  console.dir(surveyRunResults);
  return (
    <div className="dashboard">
      <Header />
      <div class="dashboard-content">
      <Snackbar
        open={copySnackbarOpen}
        autoHideDuration={5000}
        onClose={(event, reason) => {
          setCopySnackbarOpenOpen(false);
        }}
        message="Copied to clipboard successfully."
        className={'copy-snackbar'}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
        {/* <Sidebar isSurveyShown={false}></Sidebar> */}
        <Box className='left-panel results-block' style={{ width: 'auto' }}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <h1>Survey Insights</h1>
              <h2>{surveyRunResults.name}</h2>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <a className='results-copy' onClick={() => copySummary()} style={{
                color: '#0D49A0',
                textDecoration: 'none',
                cursor: 'pointer'
              }}><ContentCopy style={{ height: "17px" }} /> Copy Summary</a>
            </Grid>
          </Grid>
          <SurveySummary summary={surveyRunResults.summary} />
          <div className='review-block'>
            <div className='result-header'>
              <h1>Survey Results</h1>
              <a onClick={() => downloadFile(params.surveyRunId)} className='download'><em><img src={download} alt="download" /></em>Download Results</a>
              {/* <a onClick={() => downloadSummary(params.surveyRunId, surveyRunResults.summary)} className='download'><em><img src={download} alt="download" /></em>Download Summary</a> */}

            </div>
            <div className='results-row'>
              <div className='results-col'>
                <ul>
                  <li>
                    Total responses
                    <h2>{surveyRunResults.total_respondents || 0}</h2>
                  </li>
                  <li>
                    Total questions
                    <h2>{surveyRunResults.questions.length}</h2>
                  </li>
                  <li>
                    Audience segments
                    <h2>{surveyRunResults.audiences[0].segments.length}</h2>
                  </li>
                </ul>
                <h2>Audience</h2>
                <h4>{surveyRunResults.audiences[0].audience}</h4>
              </div>
            </div>

            {/* <div className='results-row'>
              <h2>Segments </h2>
              <div className='chart-block'>
                <StackedColumnChart />
              </div>
            </div> */}

            <div className='results-row'>
              <h2>Questions</h2>

              <QuestionResults questions={surveyRunResults.questions} segments={surveyRunResults.audiences[0].segments} answers={surveyRunResults.answers} />

            </div>

          </div>
        </Box>
      </div>
      <Footer />
    </div>
  );
};

export default Result;
