import Sidebar from './Sidebar';
import Loading from './ui/Loading';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CachedIcon from '@mui/icons-material/Cached';
import download from '../assets/download-icon.svg';
import { useApi } from '../AuthProvider';
import EditIcon from '@mui/icons-material/Edit';
import LinearProgress from '@mui/material/LinearProgress';
import { clear } from '@testing-library/user-event/dist/clear';
import DynamicTable from './DynamicTable';
import Header from './ui/Header';
import Footer from './ui/Footer';

const SurveyHistory = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDetails, setIsLoadingDetails] = useState(true);
  const [surveyRuns, setSurveyRuns] = useState([]);
  const api = useApi();
  const navigate = useNavigate();

  const [incompleteSurveyDetails, setIncompleteSurveyDetails] = useState([]);
  const [incompleteRuns, setIncompleteRuns] = useState([]);


  useEffect(() => {
    async function fetchData() {
      var surveyResponse = await api.get(`/surveys/runs/`);
      setIsLoading(false);
      setSurveyRuns(surveyResponse.data);
      console.log(surveyResponse.data);
    }
    if (api) fetchData();
  }, [api]);

  let interval;
  useEffect(() => {
    async function fetchDetails(origin) {
      await getSurveyDetails();
      setIsLoadingDetails(true);
      console.log('fetchDetails:', origin);
    }
    if (surveyRuns.length > 0) {
      // find all the survey runs that are currently running
      setIncompleteRuns(surveyRuns.filter(run => run.status === 'running' || run.status === 'pending'));
      fetchDetails('useeffect');
    }
  }, [surveyRuns]);

  useEffect(() => {
    console.log('incompleteSurveyDetails changed:', incompleteSurveyDetails);
  }, [incompleteSurveyDetails]);

  let incompleteRunList = surveyRuns.filter(run => run.status === 'running' || run.status === 'pending');

  const getSurveyDetails = async () => {

    if (incompleteRunList.length === 0) {
      // if nothing's running, stop checking
      clearInterval(interval);
      setIsLoadingDetails(false);
      return;
    } else {
      setIncompleteRuns(incompleteRunList);
      console.log('incompleteRunList:', incompleteRunList);
      // for each run in incompleteRuns, get the survey status and update it
      for (let i = 0; i < incompleteRunList.length; i++) {
        const runDetails = await api.get(`/surveys/runs/${incompleteRunList[i].survey_run_id}/status`);

        if (runDetails && runDetails.status === 200) {
          // set the data of the run in surveyRun
          surveyRuns.find(run => run.survey_run_id === incompleteRunList[i].survey_run_id).status = runDetails.data.status;
          surveyRuns.find(run => run.survey_run_id === incompleteRunList[i].survey_run_id).number_of_respondents = runDetails.data.number_of_respondents;

          if (runDetails.data.status === 'complete') {
            // if the run is completed, remove it from the list of incomplete runs
            setIncompleteRuns(incompleteRuns.filter(run => run.survey_run_id !== incompleteRunList[i].survey_run_id));
            // set the status of the run in surveyRuns to complete
            surveyRuns.find(run => run.survey_run_id === incompleteRunList[i].survey_run_id).status = runDetails.data.status;
            incompleteRunList = incompleteRunList.filter(run => run.survey_run_id !== incompleteRunList[i].survey_run_id);
          } else {
            console.log('runDetails:', runDetails.data);
            incompleteRunList[i].status_details = {
              survey_id: incompleteRunList[i].survey_id,
              status: runDetails.data.status,
              number_of_respondents: runDetails.data.number_of_respondents,
              total_respondents: runDetails.data.total_respondents,
              percent_complete: runDetails.data.percent_complete ? parseInt(Math.floor(runDetails.data.percent_complete)) : 0
            };
            setIncompleteSurveyDetails([...incompleteSurveyDetails, incompleteRunList[i].status_details]);
            // update the status (pending => running)
            console.log('incompleteSurveyDetails:', incompleteSurveyDetails);
            surveyRuns.find(run => run.survey_run_id === incompleteRunList[i].survey_run_id).status = runDetails.data.status;
          }
        } else {
          setIncompleteRuns([]);
          surveyRuns.find(run => run.survey_run_id === incompleteRunList[i].survey_run_id).status = runDetails.data.status;
          surveyRuns.find(run => run.survey_run_id === incompleteRunList[i].survey_run_id).number_of_respondents = runDetails.data.number_of_respondents;
          clearInterval(interval);
        }
      }
      setIsLoadingDetails(false);
    }
    clearInterval(interval);
    interval = setInterval(async () => {
      await getSurveyDetails();
    }, 5000)
  }

  const reRunSurvey = async (surveyId) => {
    var confirmSurveyResponse = await api.get(`/surveys/${surveyId}/run`);
    if (confirmSurveyResponse) {
      var jobData = confirmSurveyResponse.data;
      console.log('Survey run id:', jobData.survey_run_id);
    }
  }

  const downloadSummary = async (surveyRunId) => {
    // Get surveyRunResponse
    const surveyRunResponse = await api.get(`/surveys/runs/${surveyRunId}`);
    if (surveyRunResponse) {
      const surveyRunResults = surveyRunResponse.data;
      const summary = surveyRunResults.summary;

      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([summary], {
          type: 'text/markdown',
          encoding: 'UTF-8'
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${surveyRunId}.txt`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
  }

  const downloadFile = async (surveyRunId, surveyName) => {
    var downloadResponse = await api.get(`/surveys/runs/${surveyRunId}/download/xlsx`, { responseType: "blob" });
    if (downloadResponse) {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([downloadResponse.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${surveyName}.xlsx`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
  }

  function SurveyActionButtonsColumn({ surveyRunId, status, surveyId, surveyName }) {
    if (status === 'pending' || status === 'running') {
      return <td ><span><Loading /></span></td>
    } else if (status === 'error') {
      return <td>Survey Error</td>
    } else {
      return (
        <td>
          <Button onClick={() => navigate(`/result/${surveyRunId}`)} variant="outlined" className="crowdwave-blue-light">View Results</Button>
          <a onClick={() => downloadFile(surveyRunId, surveyName)} className='download active'><em><img src={download} alt="download" /></em>Download XLSX</a>
          {/* <a onClick={() => downloadSummary(surveyRunId, 'Summary')} className='download active'><em><img src={download} alt="download" /></em>Download Summary</a> */}
          {/* <a onClick={() => reRunSurvey(surveyId)} className='re-run active'><em>Re-run</em></a> */}
        </td>
      )
    }
  }

  function TableBody({ surveyRuns }) {
    if (isLoading) {
      return <tbody><tr><td colSpan={5} style={{ "textAlign": "center" }}><Loading /></td></tr></tbody>
    }
    return (
      <tbody>
        {surveyRuns.map((run) => {
          let counter = '',
            counterText = '';
          if ((run.status === 'running' || run.status === 'pending')) {
            // find the entry in incompleteSurveyDetails that matches the survey ID
            run.status_details = incompleteSurveyDetails.find(detail => detail.survey_id === run.survey_id) ? incompleteSurveyDetails.find(detail => detail.survey_id === run.survey_id) : run.status_details;

            counter = `${run.status_details?.number_of_respondents ? run.status_details?.number_of_respondents : 0} /
                        ${run.status_details?.total_respondents ? run.status_details?.total_respondents : 0}`;
            if (!run.status_details?.number_of_respondents || run.status_details?.number_of_respondents === 0) {
              counterText = "Processing...";
            } else if (run.status_details?.number_of_respondents === run.status_details?.total_respondents) {
              counterText = "Finishing up...";
            } else {
              counterText = counter;
            }
          }
          return <tr key={run.survey_run_id}>
            <td className="status">
              <span className={run.status}>{run.status}</span>
              <br />{run.name} <a onClick={() => navigate(`/copy-survey/${run.survey_id}`)}><EditIcon style={{ fontSize: "medium", cursor: "pointer" }} /></a>
            </td>
            <td>{run.created_at_formatted}</td>
            <td>{run.number_of_segments}</td>
            <td>{run.number_of_questions}</td>
            <td>
              {run.status === 'running' ? (
                <span>
                  {counterText} ({run.status_details?.percent_complete}%)
                  <LinearProgress variant="determinate" value={run.status_details ? run.status_details?.percent_complete : 0} />
                </span>
              ) : (
                run.status_details?.total_respondents ? run.status_details?.total_respondents : run.number_of_respondents
              )
              }
            </td>
            <SurveyActionButtonsColumn surveyRunId={run.survey_run_id} status={run.status} surveyId={run.survey_id} surveyName={run.name} />
          </tr>
        })}
      </tbody>
    )
  }

  const surveyRunActions = [
    {
      label: 'View Results',
      callback: (rowData) => {
        console.log('displaying survey results:', rowData);
        const surveyRunId = rowData.row.survey_run_id;
        navigate(`/result/${surveyRunId}`);
      }
    },
    {
      label: 'Copy Survey',
      callback: (rowData) => {
        console.log('copying survey:', rowData);
        const surveyId = rowData.row.survey_id;
        navigate(`/copy-survey/${surveyId}`);
      }
    },
    {
      label: 'Download XLSX',
      callback: (rowData) => {
        console.log('downloading xlsx:', rowData);
        const surveyRunId = rowData.row.survey_run_id;
        const surveyName = rowData.row.name;
        downloadFile(surveyRunId, surveyName);
      }
    }
  ];

  return (
    <div className="dashboard">
      <Header />
        <div className='dashboard-content survey-history'>
          <h1>Survey History</h1>
          {/* <div style={{ textAlign: 'right' }}><Button onClick={() => updateRunStatus()} variant="contained" startIcon={<CachedIcon />} style={{ marginBottom: "8px" }} className="crowdwave-blue">Refresh with new results</Button></div> */}
          <DynamicTable
          tableData={surveyRuns}
          visibleColumns={['name', 'created_at', 'number_of_segments', 'number_of_questions', 'number_of_respondents',]}
          columnLabels={['Name', 'Date', 'Segments', 'Questions', 'Respondents', 'Actions']}
          selectOnClick={false}
          selectable={false}
          draggable={false}
          itemName="survey run"
          actions={surveyRunActions}
          dynamicRowHeight={true}
          dynamicColumns={[{
            column: 'number_of_respondents',
            dynamicComponent: (rowData) => {
              if (rowData?.status === 'running') {
                rowData.status_details = incompleteSurveyDetails.find(detail => detail.survey_id === rowData.survey_id) ?
                                incompleteSurveyDetails.find(detail => detail.survey_id === rowData.survey_id) :
                                rowData.status_details;
                let counterText = '';
                let counter = `${rowData.status_details?.number_of_respondents ? rowData.status_details?.number_of_respondents : rowData.respondents} /
                  ${rowData.status_details?.total_respondents ? rowData.status_details?.total_respondents : 0}`;
                if (!rowData.status_details?.number_of_respondents || rowData.status_details?.number_of_respondents === 0) {
                  counterText = "Processing...";
                } else if (rowData.status_details?.number_of_respondents === rowData.status_details?.total_respondents) {
                  counterText = "Finishing up...";
                } else {
                  counterText = counter;
                }
                return (
                  <span>
                    {counterText} ({rowData.status_details?.percent_complete}%)
                    <LinearProgress variant="determinate" value={rowData.status_details ? rowData.status_details?.percent_complete : 0} />
                  </span>
                )
              } else if (rowData.status_details?.total_respondents > 0) {
                return rowData.status_details?.total_respondents;
              } else {
                return rowData.number_of_respondents;
              }
            }
          },
          {
            column: 'name',
            dynamicComponent: (rowData) => {
              return (
                <Box>
                  <span className={`${rowData.status} status`}>{rowData.status === 'running' ? 'In Progress' : rowData.status}</span> {rowData.name}
                </Box>
              )
            }
          }]}
        />
        </div>
      <Footer />
    </div>
  );
};

export default SurveyHistory;
