import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="footer">
        <div className="footer-row">
            <span>&copy; 2024 Crowdwave</span>
        </div>
        <div className="footer-row">
            <Link to={'/terms-and-conditions'} target="_blank">Terms and Conditions</Link>
            <Link to={'https://www.crowdwave.ai/faq'} target="_blank">FAQ</Link>
            <Link to={'mailto:feedback@avegroup.com'}>Feedback</Link>
            <Link to={'mailto:support@avegroup.com'}>Support</Link>
        </div>
    </div>
  );
}

export default Footer;