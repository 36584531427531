import React from 'react';
import { AppBar, Toolbar } from '@mui/material';
import logoWhite from '../../assets/logo-white.svg';
import { Link, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, alpha } from '@mui/material/styles';
import { useAuth0 } from '@auth0/auth0-react';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
    ...theme.applyStyles('dark', {
      color: theme.palette.grey[300],
    }),
  },
}));

const AccountMenu = () => {
  const { logout } = useAuth0();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (context) => {
    if (context === 'logout') {
      console.log('logout clicked');
    }
    setAnchorEl(null);
  };
console.log()
  return (
    <div className="account-menu">
      <IconButton
        id="account-menu-toggle"
        size="small"
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
      >
        <Avatar sx={{ width: 32, height: 32 }} />
      </IconButton>
      <StyledMenu
        id="account-menu"
        MenuListProps={{
          'aria-labelledby': 'account-menu-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <a onClick={() => {navigate('/welcome')}}>How To Use Crowdwave</a>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <a href='https://www.crowdwave.ai/faq' target="_blank">FAQ</a>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <a href="mailto:feedback@avegroup.com" target="_blank">Feedback</a>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <a href="mailto:support@avegroup.com" target="_blank">Support</a>
        </MenuItem>
        <MenuItem>
          <a onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>Logout</a>
        </MenuItem>
      </StyledMenu>
    </div>
  );
}

const Header = ({ isSurveyShown = false}) => {

  return (
    <AppBar
      position="static"
      className="header"
    >
      <Toolbar disableGutters>
        <div className="logo">
          <Link to={"/"} style={{ color: "white", textDecoration: "none" }}><img src={logoWhite} /></Link>
        </div>
        <ul className="nav-list">
          <li>
              <Link className={window.location.pathname === "/surveyhistory" ? "active" : "" } to={`/surveyhistory`}>
                Survey History
              </Link>
          </li>
          <li>
              <Link className={window.location.pathname === "/surveys" ? "active" : "" } to={`/surveys`}>
                Surveys
              </Link>
          </li>
          {/* <li>
              <Link className={window.location.pathname === "/share" ? "active" : "" } to={`/`}><em><img src={share} alt="Share product" /></em> Share product</Link>
          </li> */}
          {!isSurveyShown && (
          <li>
            <Link className='btn-default' to={`/create-survey`} style={{ textDecoration: 'none', margin: '20px 10px', justifyContent: 'center' }}>+ New survey</Link>
          </li>
          )}
      </ul>
        {/* account menu */}
        <AccountMenu sx={{ flexGrow: 0 }}/>
      </Toolbar>
    </AppBar>
  );
}

export default Header;