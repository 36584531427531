import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from './components/ui/Loading';
import './App.css';
import Header from './components/ui/Header';

import { useAuth0 } from '@auth0/auth0-react';
import { useApi } from './AuthProvider';

import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_PRO_KEY);

function App() {
  const [me, setMe] = useState(null);
  const { isAuthenticated, loginWithRedirect, error, isLoading, user } = useAuth0();
  const navigate = useNavigate();
  const api = useApi();


  async function getMe() {
    const meResponse = await api.get(`/users/me`);

    setMe(meResponse.data);

    if ((window.location.pathname !== "/accept-tnc" && window.location.pathname !== "/terms-and-conditions") && (meResponse.data.accepted_tos === undefined || meResponse.data.accepted_tos === false)) {
      navigate('/accept-tnc');
    }
  }

  if (error) {
    return <div>Oops... {error.message}</div>
  }

  if (isLoading) {
    return <div>
            <Loading />
          </div>
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return <Loading />
  }

  if (me === null) {
    getMe();
  }

  return (
    <div className="App">
    </div>
  );
}

export default App;
