import Step3Component from './Step3';
import Sidebar from './Sidebar';
import { useApi } from '../AuthProvider';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Audiences from './modify_views/Audiences.js';
import Questions from './modify_views/Questions.js';

import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { styled } from '@mui/material/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { tooltipClasses } from '@mui/material/Tooltip';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from './ui/Loading.js';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from 'react-router-dom';
import { Step, StepLabel, Stepper } from "@mui/material";
import group from '../assets/group.svg';
import reviews from '../assets/reviews.svg';
import shield from '../assets/shield.svg';
import SaveIcon from '@mui/icons-material/Save';
import Snackbar from '@mui/material/Snackbar';
import Header from './ui/Header.js';
import Footer from './ui/Footer.js';

const steps = ['Audience and Segments', 'Questions', 'Review and Run'];

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#202020',
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
const SurveyManager = ({ existingSurveyId, currentContext }) => {
  const [errorText, setErrorText] = useState('');
  const [loadedExistingSurvey, setLoadedExistingSurvey] = useState(false);
  const [persistedSurveyId, setPersistedSurveyId] = useState(null);
  const [surveyData, setSurveyData] = useState({});
  const api = useApi();
  const navigate = useNavigate();
  let surveyId = null;
  let surveyRunId = null;
  let audienceId = null;
  let isSaving = false;
  const [saveSurveySnackbarOpen, setSaveSurveySnackbarOpen] = useState(false);
  const addSegmentLimit = React.useState(20);
  const addQuestionsLimit = React.useState(50);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCreatingSurvey, setIsCreatingSurvey] = useState(currentContext === 'create' ? true : false);
  const [isEditingSurvey, setIsEditingSurvey] = useState(currentContext === 'edit' ? true : false);
  const [isCopyingSurvey, setIsCopyingSurvey] = useState(currentContext === 'copy' ? true : false);

  // skip to any step that the URL tells you to
  // TODO make this redundant with routes and views, QAD version for now
  const display = searchParams.get('display');
  useEffect(() => {
    console.log('searchParams:', searchParams);
    if (display === 'audiences') {
      setActiveStep(0);
    } else if (display === 'questions') {
      setActiveStep(1);
    } else if (display === 'preview') {
      setActiveStep(2);
    } else {
      setActiveStep(0);
    }
    console.log('done');
    console.log('activeStep:', activeStep);
    setSearchParams({});
  }, []);

  //step 0 related changes
  const [surveyName, setSurveyName] = useState('');
  const [hasSurveyNameError, setHasSurveyNameError] = useState(false);
  const [isShowAudienceError, setIsShowAudienceError] = useState(false);
  const [audienceData, setAudienceData] = useState([
    {
      id: 1,
      survey_id: "",
      audience: "",
      audience_id: ""
    }
  ]);
  const [existingAudiences, setExistingAudiences] = useState([]);// used to compare for edited state

  const validateSurveyName = (input) => {
    if (input.trim() === '') {
      setSurveyName(input);
      setHasSurveyNameError(true);
    } else {
      setHasSurveyNameError(false);
      setSurveyName(input);
    }
  }
  const hasNameData = surveyName.trim() !== '';
  const hasAudienceData = audienceData.some(textField => textField.audience.trim() !== '');
  const validateAudience = (input) => {
    console.log('validating audience:', input);
    if (input.trim() === '' || input === '') {
      console.log('its empty');
      setAudienceData([{ ...audienceData[0], audience: input }]);
      setIsShowAudienceError(true);
    } else {
      console.log('its not empty');
      setIsShowAudienceError(false);
      console.log('about to set audience data:', audienceData);

      setAudienceData([{ ...audienceData[0], audience: input }]);

      console.log('audience data after validate:', audienceData);
    }
  }
  const [existingSegments, setExistingSegments] = useState([]);// stores segments on load to detect add/remove later
  const [existingQuestions, setExistingQuestions] = useState([]);// stores questions on load to detect add/remove later
  const [segmentData, setSegmentData] = useState([]);
  const [newSegmentName, setNewSegmentName] = useState('');
  const [newSegmentSize, setNewSegmentSize] = useState('');
  const [hasSegmentNameError, setHasSegmentNameError] = useState(false);
  const [hasSegmentSizeError, setHasSegmentSizeError] = useState(false);
  const [hasSegmentCountError, setHasSegmentCountError] = useState(false);
  const hasSegmentData = segmentData.some(seg => seg.segment_name && seg.segment_name.trim() !== '' && seg.segment_size !== '');

  const addSegment = () => {
    console.log('adding segment');
    const segmentNameIsValid = segmentData.length !== addSegmentLimit && newSegmentName.trim() !== '' && newSegmentSize.trim() !== '';
    const segmentSizeIsValid = segmentData.length !== addSegmentLimit && newSegmentSize.trim() !== '' && parseInt(newSegmentSize) >= 1 && parseInt(newSegmentSize) <= 1000;

    if (segmentNameIsValid && segmentSizeIsValid) {
      setSegmentData([...segmentData,
        { id: newSegmentName,
          segment_name: newSegmentName,
          segment_size: parseInt(newSegmentSize),
          audience_id: audienceData[0].audience_id,
          audience_segment_id: '',
          sort_order: segmentData.length + 1,
          has_name_error: false,
          has_size_error: false
        }]);
      setNewSegmentName('');
      setNewSegmentSize('');
      setHasSegmentNameError(false);
      setHasSegmentSizeError(false);
      setHasSegmentCountError(false);
    } else {
      if (newSegmentName.trim() === '') {
        setHasSegmentNameError(true);
      }
      if (!newSegmentSize || newSegmentSize.trim() === '' || parseInt(newSegmentSize) <= 0 && parseInt(newSegmentSize) <= 1000) {
        setNewSegmentSize('');
        setHasSegmentSizeError(true);
      }
    }
  };

  // actions represent the buttons and their functions showing in the table's "Actions" col
  const segmentActions = [
    {
        label:'Delete',
        callback: async (params) => {
          console.log('deleting segment:', params.row);
          console.log(segmentData);
            const response = params.row.audience_segment_id !== '' ? await api.delete(`/audiences/segments/${params.row.audience_segment_id}`) : true;
            console.log('deleting segment:', params.row);
            console.log(segmentData);
            console.log(segmentData.filter(segment => segment.audience_segment_id !== params.row.audience_segment_id));
            if (response && response.status !== 200) {
                console.log('error deleting segment');
            } else {
                console.log('segment deleted');
                console.log(response);
            }
            // set segment data after deleting based on segment id
            setSegmentData(segmentData.filter(segment => segment.segment_name !== params.row.segment_name));
        },
    }
  ];
  const validateSegment = (name, value) => {
    if (name === 'segment_name' && value.trim() === '') {
      setHasSegmentNameError(true);
    } else if (name === 'segment_size' && (value.trim() === '' || parseInt(value) <= 0 || parseInt(value) > 1000)) {
      setHasSegmentSizeError(true);
    } else {
      setHasSegmentNameError(false);
      setHasSegmentSizeError(false);
    }
    setSegmentData(segmentData);
  };
  //step 0 end

  //step 1 related changes
  const [hasQuestionTextError, setHasQuestionTextError] = useState(false);
  const [hasQuestionOptionsError, setHasQuestionOptionsError] = useState(false);
  const [hasQuestionCountError, setHasQuestionCountError] = useState(false);
  const [questionsData, setQuestionsData] = useState([]);
  const [newQuestionText, setNewQuestionText] = useState('');
  const [newQuestionType, setNewQuestionType] = useState('Short Response');
  const [newQuestionOptions, setNewQuestionOptions] = useState('');
  const [newQuestionRankings, setNewQuestionRankings] = useState(['', '', '', '', '']);

  const questionActions = [
    {
        label:'Delete',
        callback: async (params) => {
          console.log('deleting question:', params.row);
          console.log(questionsData);
            const response = params.row.question_id !== '' ? await api.delete(`/questions/${params.row.question_id}`) : true;
            console.log('deleting question:', params.row);
            console.log(questionsData);
            console.log(questionsData.filter(ques => ques.question_id !== params.row.question_id));
            if (response && response.status !== 200) {
                console.log('error deleting question');
            } else {
                console.log('question deleted');
                console.log(response);
            }
            setQuestionsData(questionsData.filter(ques => ques.question_text !== params.row.question_text));
        },
    }
  ];

  const validateQuestion = () => {
    if (0 !== questionsData.length !== addQuestionsLimit && newQuestionText.trim() !== '') {
      setHasQuestionTextError(false);
      return true;
    } else {
      setHasQuestionTextError(true);
      return false;
    }
  }
  const validateOptions = () => {
    if (newQuestionType !== 'Single Choice' && newQuestionType !== 'Multiple Choice') {
      return true;
    } else {
      if (newQuestionOptions.trim() === '') {
        setHasQuestionOptionsError(true);
        return false;
      } else {
        setHasQuestionOptionsError(false);
        return true;
      }
    }
  }

  const hasQuestionsData = questionsData.some(textField => textField.question_text !== '' && textField.question_type !== '');
  const addQuestion = () => {

    if (questionsData.length !== addQuestionsLimit && validateQuestion() && validateOptions()) {
      console.log('adding question');
      console.log('newQuestionText:', newQuestionText);
      console.log('newQuestionOptions:', newQuestionOptions);
      console.log('newQuestionType:', newQuestionType);
      console.log('newQuestionRankings:', newQuestionRankings);
      setQuestionsData(
        [...questionsData,
        {
          id: Math.floor(Math.random() * 10000),
          question_text: newQuestionText,
          question_type: newQuestionType,
          survey_id: isEditingSurvey ? surveyData.survey_id : '',
          sort_order: questionsData.length + 1,
          raw_options: newQuestionOptions,
          options: newQuestionOptions ? newQuestionOptions.split(',') : [],
          rankings: ['', '', '', '', ''],
          questions: '',
          question_id: "",
          has_question_error: false
        }
        ]);
      // reset inputs
      setNewQuestionText('');
      setNewQuestionType('Short Response');
      setNewQuestionOptions('');
      setHasQuestionCountError(false);
    }
  };

  const handleQuestionTypeFieldChange = (event) => {
    setNewQuestionType(event.target.value);
  };

  const handleRemoveValidationForQuestionsChange = (field, e) => {
    if (e.target.value.trim() !== '') {
      setHasQuestionTextError(false);
    }
    if (field === 'options' && e.target.value.trim() !== '') {
      setHasQuestionOptionsError(false);
    }
  };

  //step 1 end
  useEffect(() => {
      console.log('name changed:', surveyName);
      setSurveyData({ ...surveyData, name: surveyName });
  }, [surveyName]);
  useEffect(() => {
      console.log('audience changed:', audienceData);
      //validateAudience(audienceData[0].audience);
      setSurveyData({
        ...surveyData,
        survey_id: persistedSurveyId,
        audiences:[ ...audienceData ]
      });
  }, [audienceData]);
  useEffect(() => {
    if (segmentData.length > 0) {
      console.log('segment changed:', segmentData);
      setSurveyData({ ...surveyData, audiences: [{
        ...surveyData.audiences[0],
        segments: segmentData
      }]});
    }
  }, [segmentData]);
  useEffect(() => {
    if (questionsData.length > 0) {
      console.log('questions changed:', questionsData);
      setSurveyData({ ...surveyData, questions: questionsData });
    }
  }, [questionsData]);
  useEffect(() => {
    console.log('surveyData changed:', surveyData);
    // check if we're actively saving
    if (isSaving) {
      if (surveyData.survey_id !== null && surveyData.survey_id !== '') {
        // survey has been created
        if (surveyData.audiences[0].audience_id !== null && surveyData.audiences[0].audience_id !== '') {
          // audience has been created
        }
      }
    }
  }, [surveyData]);


  const isStepSkipped = (step) => {
    return skipped.has(step);
  };


  const handleCreateNext = async () => {
    let newSkipped = skipped;
    var isError = false;

    // start with no errors
    setErrorText('');

    // Fixed issue with calling createAudience() multiple times
    // by seperating coniditional check for activeStep === 0
    // and checking if audienceData is not empty
    if (activeStep === 0) {
      // audience check null or not
      if (!hasAudienceData || hasSurveyNameError || surveyName.trim() === '') {
        isError = true;
      }
      !hasAudienceData || isShowAudienceError ? setIsShowAudienceError(true) : setIsShowAudienceError(false);
      hasSurveyNameError || surveyName.trim() === '' ? setHasSurveyNameError(true) : setHasSurveyNameError(false);
    // segment check if null or not
      const segmentsWithBlankName = segmentData.some(seg => seg.segment_name.trim() === '');
      const segmentsWithBlankSize = segmentData.some(seg => seg.segment_size === '');

      if (segmentData.length === 0) {
        //setErrorText('Please add at least one segment to proceed');
        setHasSegmentCountError(true);
        return;
      } else if (segmentsWithBlankSize || segmentsWithBlankName) {
        setHasSegmentCountError(false);
        setSegmentData(segmentData.map(seg => {
          return {
            ...seg,
            has_name_error: seg.segment_name.trim() === '',
            has_size_error: seg.segment_size === ''
          }
        }));
        return;
      }
    }

    // question check if null or not
    if (activeStep === 1) {
      const questionsWithNoQuestion = questionsData.some(ques => ques.question_text.trim() === '');

      if (questionsData.length === 0) {
        // setErrorText('Please add at least one question to proceed');
        setHasQuestionCountError(true);
        return;
      }

      if (questionsWithNoQuestion) {
        setQuestionsData(questionsData.map(ques => {
          return {
            ...ques,
            has_question_error: ques.question_text.trim() === ''
          }
        }));
        return;
      }
    }

    // if nt error it will redirect to next page
    if (!isError) {
      if (activeStep === 0) {
        console.log('step 0');;
        // name / audience / segments
        console.log(activeStep);
        saveSurvey();
        setActiveStep(activeStep + 1);
      } else if (activeStep === 1) {
        // questions
        console.log('step 1');
        saveSurvey();
        setActiveStep(activeStep + 1);
      } else if (activeStep === 2) {
        // review
        console.log('step 2');
        setActiveStep(activeStep + 1);

        // create new survey mode, so create all the elements of the survey
        let stepSuccess = false;

        stepSuccess = await runSurvey();

        if (stepSuccess !== true) {
          setErrorText('Error running survey');
          setActiveStep(2);
          return;
        }

        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
        }

    }
  };

  const selectSteps = (step) => {
    setActiveStep(step);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };



  const createSurvey = async (surveyName) => {
    if (persistedSurveyId !== null) {
      surveyId = persistedSurveyId;
      console.log('persistedSurveyId:', surveyId);
    }

    if (surveyId !== null && surveyId !== '') {
      return surveyId;
    }

    try {
      const response = await api.post('/surveys/', {
        name: surveyName.substring(0, 255),
      });

      return response.data.survey_id;

    } catch (error) {
      console.error('Error making POST request', error);
      setErrorText(error.message || 'Error creating survey');
    }
  };

  const createAudience = async () => {
    console.log('createAudience');
    console.log(isEditingSurvey, persistedSurveyId)
    if (isEditingSurvey && persistedSurveyId !== null) {
      surveyId = persistedSurveyId;
    }

    if (isCreatingSurvey || isCopyingSurvey) {
      console.log('creating a new audience...');
      try {
        surveyId = await createSurvey(surveyName);
      } catch (error) {
        console.error('Error making POST request', error);
        setErrorText(error.message || 'Error creating survey');
        return false;
      } finally {
        console.log('finally surveyId:', surveyId);

        setPersistedSurveyId(surveyId);
        setAudienceData([{ ...audienceData[0], survey_id: surveyId }]);
        // DEBUG: request and log the details of the newly created survey
        const surveyResponse = await api.get(`/surveys/${surveyId}`);
        console.log('surveyResponse', surveyResponse);
        if (surveyResponse && surveyResponse.status === 200) {
          console.log('survey created:', surveyResponse.data);
          try {
            const response = await api.post('/audiences/', {
              survey_id: surveyResponse.data.survey_id,
              audience: audienceData[0].audience,
            });
      
            if (response && response.status !== 200) {
              console.error('Error making POST request', response.data);
              setErrorText(response.data.message || 'Error creating audience');
              return false;
            } else {
              console.log('posted to audiences/:', response.data);

              audienceId = response.data.audience_id;
              setAudienceData([{ ...audienceData[0], survey_id: response.data.survey_id, audience_id: response.data.audience_id }]);
              setExistingAudiences(audienceData);
              if (segmentData.length > 0) {
                const segmentSuccess = await saveSegments(response.data.audience_id);

                if (segmentSuccess !== true) {
                  setErrorText('Error creating segments');
                  setActiveStep(0);
                  return;
                } else {
                  console.log('segments created');
                }
              }
            }
          } catch (error) {
            console.error('Error making POST request', error);
            setErrorText(error || 'Error creating audience');
            return false;
          }
        }
      }
    }

    return true;
  };

  const saveSegments = async (audienceId) => {
    console.log('***************** saveSegments *****************');
    if (hasSegmentData) {
      console.log('existingSegments:', existingSegments);
      console.log('audienceId:', audienceId);
      let newSegments = [],
          editedSegments = [];

      if (currentContext !== 'create') {
        // figure out which segments are new and which are existing by comparing
        // existingSegments with current segmentData
        newSegments = segmentData.filter(seg => !existingSegments.some(origSeg => origSeg.audience_segment_id === seg.audience_segment_id));
        console.log('newSegments:', newSegments);
        // figure out which items from segmentData are from existingSegments but have been edited
        editedSegments = segmentData.filter(seg => existingSegments.some(origSeg => origSeg.audience_segment_id === seg.audience_segment_id && (origSeg.segment_name !== seg.segment_name || origSeg.segment_size !== seg.segment_size)));
        console.log('editedSegments:', editedSegments);
      } else {
        newSegments = segmentData;
      }

      if (newSegments.length > 0) {
        // make sure it's not a duplicate of an existing segment by comparing their IDs
        newSegments.forEach(newSeg => {
          if (existingSegments.some(origSeg => origSeg.audience_segment_id === newSeg.audience_segment_id)) {
            newSegments = newSegments.filter(seg => seg.audience_segment_id !== newSeg.audience_segment_id);
          }
        });

        try {
          // create new segments
          console.log('trying to create new segments');
          console.log('audienceData:', audienceData);
          console.log('surveyData:', surveyData);
          console.log(newSegments);
          const segmentResponse = await api.post(`/audiences/segments/bulk`, {
            audience_id: audienceId,
            segments: newSegments
          });
          if (segmentResponse && segmentResponse.status === 200) {
            console.log('new segments updated');
            console.log('segmentResponse:', segmentResponse);
            // for every segment in segmentData, update the corresponding audience_segment_id and audience_id
            // with the one returned from the server
            segmentData.forEach((seg, index) => {
              if (!seg.audience_segment_id) {
                seg.audience_segment_id = segmentResponse.data[index].audience_segment_id;
              }
              if (!seg.audience_id) {
                seg.audience_id = audienceId;
              }
            });
          }
        } catch (error) {
          console.error('Error making POST request', error);
          setErrorText(error.message || 'Error creating new survey segments');
        }
      }
      // update edited segments
      if (editedSegments.length > 0) {
        for (let i = 0; i < editedSegments.length; i++) {
          try {
            const segmentResponse = await api.put(`/audiences/segments/${editedSegments[i].audience_segment_id}`, {
              segment_name: editedSegments[i].segment_name,
              segment_size: parseInt(editedSegments[i].segment_size),
              sort_order: editedSegments[i].sort_order
            });
            if (segmentResponse && segmentResponse.status === 200) {
              console.log('edited segments updated');
            }
          } catch (error) {
            console.error('Error making POST request', error);
            setErrorText(error.message || 'Error editing survey segments');
          }
        }
      }

      try {
        if (persistedSurveyId !== null) {
          surveyId = persistedSurveyId;
        }

        // stitch together original, new, and edited segments (if any exist)
        const allSegments = existingSegments.concat(newSegments).concat(editedSegments);
        console.log('allSegments:', allSegments);
        setSegmentData(allSegments);
        setExistingSegments(allSegments);

      } catch (error) {
        if (error.response) {
          console.error('Server error:', error.response.status, error.response.data);
          setErrorText(error.response.data.message);
        } else if (error.request) {
          console.error('Network error:', error.request);
          setErrorText(error.request);
        } else {
          console.error('Error:', error.message);
          setErrorText(error.message);
        }
        return false;
      }
    }

    return true;
  }

  const saveQuestions = async (survey_id) => {
    if (questionsData.length > 0) {
      // figure out which questions are new and which are existing by comparing
      // existingQuestions with current questionsData
      console.log('****** saving questions');
      console.log('existingQuestions:', existingQuestions);
      console.log('questionsData:', questionsData);
      const newQuestions = questionsData.filter(ques => !existingQuestions.some(origQues => origQues.question_id === ques.question_id));
      //
      console.log('newQuestions:', newQuestions);
      // filter questionsData for questions that have the same ID as existingQuestions but have been edited
      const editedQuestions = questionsData.filter(ques => existingQuestions.some(origQues => origQues.question_id === ques.question_id && (origQues.question_text !== ques.question_text || origQues.question_type !== ques.question_type || origQues.raw_options !== ques.raw_options || origQues.sort_order !== ques.sort_order)));
      console.log('editedQuestions:', editedQuestions);
      if (existingQuestions.length === 0 && newQuestions.length === 0 && editedQuestions.length === 0) {
        console.log('all empty');
        console.log('questionsData:', questionsData);
      } else if (existingQuestions.length === 0) {
        // there are no existing questions, so all questions in questionsData are new
        if (newQuestions.length > 0) {
          try {
            console.log('trying to create new questions');
            console.log('audienceData:', audienceData);
            console.log('audienceData[0].survey_id:', audienceData[0].survey_id);
            console.log('newQuestions:', newQuestions);
            // create new questions
            const questionResponse = await api.post(`/questions/bulk`, {
              survey_id: survey_id,
              questions: newQuestions
            });
            if (questionResponse && questionResponse.status === 200) {
              console.log('new questions updated');
              // for every question in questionsData, update the corresponding question_id and survey_id
              // with the one returned from the server
              questionsData.forEach((ques, index) => {
                if (!ques.question_id) {
                  ques.question_id = questionResponse.data[index].question_id;
                  ques.survey_id = questionResponse.data[index].survey_id;
                }
              });
            }
          } catch (error) {
            console.error('Error making POST request', error);
            setErrorText(error.message || 'Error creating new survey questions');
          }
        }
      } else {
        // there are existing questions, so we need to update the existing ones and create the new ones
        if (newQuestions.length > 0) {
          try {
            // create new questions
            const questionResponse = await api.post(`/questions/bulk`, {
              survey_id: survey_id,
              questions: newQuestions
            });
            if (questionResponse && questionResponse.status === 200) {
              console.log('new questions updated');
              // for every question in questionsData, update the corresponding question_id and survey_id
              // with the one returned from the server
              questionsData.forEach((ques, index) => {
                if (!ques.question_id) {
                  ques.question_id = questionResponse.data[index].question_id;
                  ques.survey_id = questionResponse.data[index].survey_id;
                }
              });
            }
          } catch (error) {
            console.error('Error making POST request', error);
            setErrorText(error.message || 'Error creating new survey questions');
          }
        }
        if (editedQuestions.length > 0) {
          // update edited questions
          for (let i = 0; i < editedQuestions.length; i++) {
            try {
              const questionResponse = await api.put(`/questions/${editedQuestions[i].question_id}`, {
                question_text: editedQuestions[i].question_text,
                question_type: editedQuestions[i].question_type,
                sort_order: editedQuestions[i].sort_order,
                raw_options: editedQuestions[i].raw_options,
                options: editedQuestions[i].raw_options ? editedQuestions[i].raw_options.split(',') : [""]
              });
              if (questionResponse && questionResponse.status === 200) {
                console.log('edited questions updated');
              }
            } catch (error) {
              console.error('Error making POST request', error);
              setErrorText(error.message || 'Error editing survey questions');
            }
          }
        }
      }
      // stitch together original, new, and edited questions (if any exist)
      const allQuestions = existingQuestions.concat(newQuestions).concat(editedQuestions);
      console.log('allQuestions:', allQuestions);
      setQuestionsData(allQuestions);
      setExistingQuestions(allQuestions);
      isSaving = false;
    }

    return true;
  }

  // this locks the "Options" column for editing in the Questions DynamicTable component for non-options question types,
  // because we want that col to be editable for single/multi choice questions only
  const enableOptionsEditing = (params) => {
    if (params.field === 'raw_options' && (params.row.question_type !== 'Single Choice' && params.row.question_type !== 'Multiple Choice')) {
      return false;
    } else {
      return true;
    }
  }

  const runSurvey = async () => {
    if (surveyId === null) {
      surveyId = surveyData.survey_id;
    }
    const confirmSurveyResponse = await api.get(`/surveys/${surveyId}/run`);

    if (confirmSurveyResponse) {
      const jobData = confirmSurveyResponse.data;
      surveyRunId = jobData.survey_run_id;
      surveyId = null;
      setPersistedSurveyId(null);
      audienceId = null;
    }

    navigate('/surveyhistory');
  }

  const handleRemoveQuestion = (index) => {
    setQuestionsData(questionsData.filter((_, i) => i !== index));
  }

  const handleUpload = async (event) => {
    if (event.target.files.length === 0) {
      setErrorText('Please select a file to upload');
      return;
    } else {
      setErrorText('');
      // xlsx upload endpoint needs an ID, so we need to create a survey first
      const createResponse = await createSurvey(surveyName);
      surveyId = createResponse;
      setPersistedSurveyId(surveyId);
      try {
        const response = await api.post(`/surveys/${surveyId}/upload-questions/xlsx`,
          {
            'xlsx_file': event.target.files[0],
          },
          { headers: { 'Content-Type': 'multipart/form-data' } }
        );
        if (response && response.status === 200) {

          // now that questions have been uploaded, pull them back from the api
          const getSurvey = await api.get(`/surveys/${surveyId}`);
          if (getSurvey && getSurvey.status === 200) {
            // questions in the DB only need options[] but in the ui we need raw_options for the table, so we create them before we set the questions data
            getSurvey.data.questions.forEach((question, index) => {
              question.options.length > 0 ? question.raw_options = question.options.join(', ') : question.raw_options = '';
            });
            setQuestionsData(getSurvey.data.questions);
          }
        }
      } catch (error) {
        console.error('Error making POST request', error);
        setErrorText(error.message || 'Error uploading survey questions from XLSX');
      }
    }
  }

  const ErrorAlert = ({ errorText }) => {
    if (!errorText || errorText === '') {
      return;
    }

    return (
      <Alert
        severity="error"
        style={{ marginBottom: "20px", borderRadius: "20px" }}
        onClose={() => { setErrorText('') }}>
        <AlertTitle>Error</AlertTitle>
        {errorText}
      </Alert>
    );
  }

  const saveSurvey = async () => {
    console.log('saving survey', persistedSurveyId);
    console.log('surveyName:', surveyName);
    console.log('audienceData', audienceData);
    console.log('existingSegments', existingSegments);
    console.log('segmentData', segmentData);
    console.log('questionsData', questionsData);
    console.log('surveyData', surveyData);
    console.log('isEditingSurvey', isEditingSurvey);
    console.log('isCreatingSurvey', isCreatingSurvey);
    console.log('isCopyingSurvey', isCopyingSurvey);
    isSaving = true;
    if (!hasNameData || !hasAudienceData) {
        // audience check null or not
        !hasAudienceData ? setIsShowAudienceError(true) : setIsShowAudienceError(false);
        hasSurveyNameError || surveyName.trim() === '' ? setHasSurveyNameError(true) : setHasSurveyNameError(false);
      return;
    }
    // stitch together surveyData using the current state-based audienceData, segmentData, and questionsData
    let newSurveyData = {};

    newSurveyData = {
      ...surveyData,
      survey_id: persistedSurveyId,
      name: surveyName,
      audiences: [{
        ...audienceData[0],
        segments: segmentData
    }],
      questions: questionsData
    };

    console.log('beginning save with newSurveyData', newSurveyData);

    // regardless of the current step, when the user clicks save, we want to determine whether
    // or not they're editing an existing survey. If so, we update that survey and its associated entities.
    // If not, then upon each save we create new entities and associate them with the new survey. Upon first save, we use
    // audience to create a survey (and survey_id)

    if (isEditingSurvey) {
      // editing existing survey
      if (activeStep === 0) {
      try {
          // attempt to save name
          console.log('surveyName:', surveyName);
          const nameResponse = await api.put(`/surveys/${surveyData.survey_id}`, { name: surveyData.name });
          if (nameResponse && nameResponse.status === 200) {
            console.log('name updated');
          }
        } catch (error) {
          console.error('Error making POST request', error);
          setErrorText(error.message || 'Error creating survey name');
        }
        // update audience
        try {
          console.log('audienceData:', audienceData);
          const audienceResponse = await api.put(`/audiences/${audienceData[0].audience_id}`, { audience: audienceData[0].audience });
          if (audienceResponse && audienceResponse.status === 200) {
            console.log('audience updated');
          }
        } catch (error) {
          console.error('Error making POST request', error);
          setErrorText(error.message || 'Error creating survey audience');
          isSaving = false;
        }
      }
      await saveSegments(audienceData[0].audience_id);
      await saveQuestions(surveyData.survey_id);

    } else if (isCreatingSurvey || isCopyingSurvey) {
      // creating / copying survey
      console.log('saving create');
      console.log(persistedSurveyId);

      if (activeStep === 0) {
        // if null/'', that means it's a create / copy that hasn't saved its audience yet
        if (audienceData[0].audience_id === null || audienceData[0].audience_id === '') {
          const createSuccess = await createAudience();// this creates an audience and calls createSurvey
          if (createSuccess !== true) {
            setErrorText('Error creating audience');
            setActiveStep(0);
            isSaving = false;
            return;
          } else {
            console.log('audience created');
            console.log(persistedSurveyId);
            console.log('surveyData:', surveyData);
            // as soon as we save successfully, we are now editing and no longer creating/copying
            setIsCopyingSurvey(false);
            setIsCreatingSurvey(false);
            setIsEditingSurvey(true);
          }
          isSaving = false;
        } else {
          // there's a surveyId/persistedSurveyId, so we need to compare audience with existingAudience
          // to see if its been edited and if so, update it
          console.log('audienceData:', audienceData);
          console.log('existingAudiences:', existingAudiences);
          if (existingAudiences.length > 0) {
            // check if audience has been edited
            if (audienceData[0].audience !== existingAudiences[0].audience) {
              try {
                const audienceResponse = await api.put(`/audiences/${audienceData[0].audience_id}`, { audience: audienceData[0].audience });
                if (audienceResponse && audienceResponse.status === 200) {
                  console.log('audience updated');
                  // as soon as we save successfully, we are now editing and no longer creating/copying
                  setIsCopyingSurvey(false);
                  setIsCreatingSurvey(false);
                  setIsEditingSurvey(true);
                } else {
                  setErrorText('Error updating audience');
                  setActiveStep(0);
                  isSaving = false;
                  return;
                }
              } catch (error) {
                console.error('Error making POST request', error);
                setErrorText(error.message || 'Error updating audience');
                setActiveStep(0);
                isSaving = false;
                return;
              }
            }
          }
        }
      } else if (activeStep === 1) {
        if (questionsData.length > 0) {
          await saveQuestions(surveyData.survey_id);
        }
      }
    }

      // if (hasSegmentData) {
      //   console.log('hasSegmentData:', hasSegmentData);
      //   console.log('segmentsData:', segmentData);
      //   const segmentSuccess = await saveSegments();

      //   if (segmentSuccess !== true) {
      //     setErrorText('Error creating segments');
      //     setActiveStep(1);
      //     return;
      //   } else {
      //     console.log('segments created');
      //   }
      // }

      // if (hasQuestionsData) {
      //   const questionSuccess = await saveQuestions();

      //   if (questionSuccess !== true) {
      //     setErrorText('Error creating questions');
      //     setActiveStep(2);
      //     return;
      //   } else {
      //     console.log('questions created');
      //   }
      // }
    // if (segmentData.length > 0) {
    //   await saveSegments();
    // }

    // if (questionsData.length > 0) {
    //   await saveQuestions();
    // }

    //   validateSurveyName(surveyName);
    //   validateAudience(audienceData[0].audience);
    //   for (let i = 0; i < segmentData.length; i++) {
    //     validateSegment('segment_name', segmentData[i].segment_name);
    //     validateSegment('segment_size', segmentData[i].segment_size);
    //   }
    // for (let i = 0; i < questionsData.length; i++) {
    //   validateQuestion('question_text', questionsData[i].question_text);
    //   validateQuestion('options', questionsData[i].raw_options);
    // }

    // if (persistedSurveyId) {
    //   const response = await api.put(`/surveys/${persistedSurveyId}`, newSurveyData);
    //   if (response && response.status === 200) {
    //     console.log('surveydata updated, response:', response);
    //     console.log('surveyData after update:', surveyData);
    //     console.log('newSurveyData:', newSurveyData);
    //     setSaveSurveySnackbarOpen(true);
    //   } else {
    //     setErrorText('Error saving survey');
    //     console.log(response.data);
    //   }
    // }
  }

  const loadExistingSurvey = async () => {
    console.log('loading existing survey');
    console.log('isEditingSurvey:', isEditingSurvey);
    console.log('isCopyingSurvey:', isCopyingSurvey);
    console.log('existingSurveyId:', existingSurveyId);
    console.log('surveyId:', surveyId);
    console.log('persistedSurveyId:', persistedSurveyId);

    if (surveyId !== null || isCreatingSurvey) return;

    const response = await api.get(`/surveys/${existingSurveyId}`);

    if (response && response.status === 200) {
      setLoadedExistingSurvey(true);
      console.log('found survey');
      let existingSurvey = response.data;
      console.log('existingSurvey:', existingSurvey);
      console.log('surveyData:', surveyData);

      if (isEditingSurvey) {
        console.log('loading for edit...');
        // load the existing survey in its entirety
        surveyId = existingSurvey.survey_id;
        setPersistedSurveyId(surveyId);
        setSurveyName(existingSurvey.name);
        setAudienceData([{ ...existingSurvey.audiences[0]  }]);
        setSegmentData(existingSurvey.audiences[0].segments);
        setExistingSegments(existingSurvey.audiences[0].segments);
        setQuestionsData(existingSurvey.questions);
        setExistingQuestions(existingSurvey.questions);
        setSurveyData(existingSurvey);
        console.log('done loading for edit, surveyData:', surveyData);
      } else if (isCopyingSurvey) {
        // copying survey, so we want to create a new survey with empty IDs for all entities
        console.log('loading for copy...');
        surveyId = '';
        setPersistedSurveyId('');
        setSurveyName(existingSurvey.name + ' (copy)');
        // clean up segments before adding to audienceData
        setSegmentData(existingSurvey.audiences[0].segments.map((seg, index) => {
          return {
            id: index + 1,
            audience_id: '',
            segment_name: seg.segment_name,
            segment_size: parseInt(seg.segment_size),
            audience_segment_id: '',
            has_name_error: false,
            has_size_error: false,
            sort_order: seg.sort_order
          }
        }));
        setAudienceData([{
          ...existingSurvey.audiences[0],
          created_at: '',
          audience_id: '',
          segments: segmentData
        }]);
        setExistingAudiences(audienceData);
        setExistingSegments(segmentData);
        setQuestionsData(existingSurvey.questions.map((ques, index) => {
          return {
            id: index + 1,
            survey_id: '',
            question_text: ques.question_text,
            question_type: ques.question_type,
            sort_order: ques.sort_order,
            raw_options: ques.options.join(', '),
            options: ques.options,
            rankings: ['', '', '', '', ''],
            questions: ques.options.join(', '),
            question_id: '',
            has_question_error: false
          }
        }));
        setExistingQuestions(questionsData);
        // setSurveyData({
        //   survey_id: '',
        //   name: surveyName,
        //   audiences: audienceData,
        //   questions: questionsData,
        //   created_at: '',
        //   created_by: ''
        // });
        console.log('done loading for copy, surveyData:', surveyData);
      } else {
        // shouldn't happen
        console.log('invalid survey context');
      }
      

      // if (isEditingSurvey) {
      //   // editing, set audience, segment, and questions data from existing survey
      //   // console.log('loaded existing for editing, isEditingSurvey:', isEditingSurvey);

      //   // audienceId = existingSurvey.audiences[0].audience_id;
      //   // setExistingSegments(existingSurvey.audiences[0].segments);
      //   // setSegmentData(existingSurvey.audiences[0].segments.map((seg, index) => {
      //   //   return {
      //   //     id: index + 1,
      //   //     audience_id: existingSurvey.audiences[0].audience_id,
      //   //     segment_name: seg.segment_name,
      //   //     segment_size: seg.segment_size,
      //   //     audience_segment_id: seg.audience_segment_id,
      //   //     has_name_error: false,
      //   //     has_size_error: false,
      //   //     sort_order: seg.sort_order
      //   //   }
      //   // }));
      //   // setExistingQuestions(existingSurvey.questions);
      //   // setQuestionsData(existingSurvey.questions.map((ques, index) => {
      //   //   return {
      //   //     id: index + 1,
      //   //     survey_id: existingSurvey.survey_id,
      //   //     question_text: ques.question_text,
      //   //     question_type: ques.question_type,
      //   //     sort_order: ques.sort_order,
      //   //     raw_options: ques.options.join(', '),
      //   //     options: ques.options,
      //   //     rankings: ['', '', '', '', ''],
      //   //     questions: ques.options.join(', '),
      //   //     question_id: ques.question_id,
      //   //     has_question_error: false
      //   //   }
      //   // }));

      // } else {
      //   // duplicating: populate all data from existingSurvey
      //   // but set survey_id to "" to create a new survey
      //   console.log('loading existing survey for duplication');
      //   console.log('existingSurvey:', existingSurvey);

      //   setAudienceData([{ ...existingSurvey.audiences[0], created_at: '', audience_id: '' }]);
      //   setSegmentData(existingSurvey.audiences[0].segments.map((seg, index) => {
      //     return {
      //       id: index + 1,
      //       audience_id: existingSurvey.audiences[0].audience_id,
      //       segment_name: seg.segment_name,
      //       segment_size: seg.segment_size,
      //       audience_segment_id: '',
      //       has_name_error: false,
      //       has_size_error: false,
      //       sort_order: seg.sort_order,
      //       created_at: '',
      //     }
      //   }));
      //   setExistingSegments(segmentData);
      //   setQuestionsData(existingSurvey.questions.map((ques, index) => {
      //     return {
      //       id: index + 1,
      //       survey_id: "",
      //       question_text: ques.question_text,
      //       question_type: ques.question_type,
      //       sort_order: ques.sort_order,
      //       raw_options: ques.options.join(', '),
      //       options: ques.options,
      //       rankings: ['', '', '', '', ''],
      //       questions: ques.options.join(', '),
      //       question_id: '',
      //       has_question_error: false,
      //       created_at: '',
      //     }
      //   }));
      //   setExistingQuestions(questionsData);
      //   console.log('copy load complete');
      // }

      console.log('load complete');
    } else {
      setErrorText('Error loading existing survey');
      console.log(response.data);
      surveyId = 0;
      setPersistedSurveyId(0);
    }
  }


  if (existingSurveyId !== null && loadedExistingSurvey === false) {
    loadExistingSurvey();
    return <Loading />;
  }

  return (
    <div className="dashboard">
      <Header isSurveyShown={true}/>
        <div className="dashboard-content">
          <div>
          <Snackbar
            open={saveSurveySnackbarOpen}
            autoHideDuration={3000}
            onClose={(event, reason) => {
              setSaveSurveySnackbarOpen(false);
            }}
            message="Data successfully saved"
            className={'save-snackbar'}
            severity="success"
            variant="filled"
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          />
            <ErrorAlert errorText={errorText} />
            {
              activeStep === steps.length ? (
                <div className='left-panel' style={{ width: 'auto' }}>
                  <React.Fragment>
                    <div className='no-survey'>
                      <Loading />
                      <h2 sx={{ mt: 2, mb: 1 }}>
                        Please wait...
                        <span>simulating your survey</span>
                      </h2>
                    </div>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Box sx={{ flex: '1 1 auto' }} />
                      {/* <Button onClick={handleReset}>Reset</Button> */}
                    </Box>
                  </React.Fragment>
                </div>
              )
                :
                (
                  <React.Fragment>
                    <h1>{currentContext} Survey</h1>
                    {((window.location.pathname === "/" || window.location.pathname.includes('-survey')) && activeStep !== steps.length) && (
                        <Grid container className="stepper">
                          <Box>
                            <Stepper activeStep={activeStep}>
                              <Step key="Step 1">
                                <StepLabel>Audience & Segments</StepLabel>
                              </Step>
                              <Step key="Step 2">
                                <StepLabel>Questions</StepLabel>
                              </Step>
                              <Step key="Step 3">
                                <StepLabel>Review</StepLabel>
                              </Step>
                            </Stepper>
                          </Box>
                        </Grid>
                      )}
                    <div className='step' style={{ display: activeStep === 0 ? 'block' : 'none' }}>
                      <Audiences
                        activeStep={activeStep}
                        audienceData={audienceData}
                        setAudienceData={setAudienceData}
                        hasAudienceData={hasAudienceData}
                        hasSurveyNameError={hasSurveyNameError}
                        surveyName={surveyName}
                        isShowAudienceError={isShowAudienceError}
                        setIsShowAudienceError={setIsShowAudienceError}
                        validateSurveyName={validateSurveyName}
                        validateAudience={validateAudience}
                        segmentData={segmentData}
                        setSegmentData={setSegmentData}
                        hasSegmentData={hasSegmentData}
                        addSegment={addSegment}
                        addSegmentLimit={addSegmentLimit}
                        newSegmentName={newSegmentName}
                        hasSegmentNameError={hasSegmentNameError}
                        newSegmentSize={newSegmentSize}
                        hasSegmentSizeError={hasSegmentSizeError}
                        hasSegmentCountError={hasSegmentCountError}
                        setNewSegmentName={setNewSegmentName}
                        setNewSegmentSize={setNewSegmentSize}
                        validateSegment={validateSegment}
                        segmentActions={segmentActions}
                        currentContext={currentContext}
                      />
                    </div>
                    <div className="step" style={{ display: activeStep === 1 ? 'block' : 'none' }}>
                      <Questions
                        activeStep={activeStep}
                        questionsData={questionsData}
                        setQuestionsData={setQuestionsData}
                        hasQuestionsData={hasQuestionsData}
                        handleUpload={handleUpload}
                        newQuestionText={newQuestionText}
                        setNewQuestionText={setNewQuestionText}
                        hasQuestionTextError={hasQuestionTextError}
                        handleRemoveValidationForQuestionsChange={handleRemoveValidationForQuestionsChange}
                        newQuestionType={newQuestionType}
                        handleQuestionTypeFieldChange={handleQuestionTypeFieldChange}
                        newQuestionOptions={newQuestionOptions}
                        setNewQuestionOptions={setNewQuestionOptions}
                        hasQuestionOptionsError={hasQuestionOptionsError}
                        addQuestion={addQuestion}
                        addQuestionsLimit={addQuestionsLimit}
                        validateQuestion={validateQuestion}
                        enableOptionsEditing={enableOptionsEditing}
                        hasQuestionCountError={hasQuestionCountError}
                        audienceData={audienceData}
                        hasAudienceData={hasAudienceData}
                        segmentData={segmentData}
                        hasSegmentData={hasSegmentData}
                        selectSteps={selectSteps}
                        questionActions={questionActions}
                        currentContext={currentContext}
                      />
                    </div>

                    <div className="step" style={{ display: activeStep === 2 ? 'block' : 'none' }}>
                      <Step3Component
                        surveyName={surveyName}
                        audienceData={audienceData}
                        segmentData={segmentData}
                        questionsData={questionsData}
                        hasSegmentData={hasSegmentData}
                        selectSteps={selectSteps} />
                    </div>
                    <Box className="btnblock" sx={{ display: 'flex', alignSelf: "flex-end", flexDirection: 'row', pt: 2, width: (activeStep === steps.length - 1) ? '95%' : '66.6%' }}>
                      <Box sx={{ flex: '2'}}></Box>
                      <Button variant="outlined"
                        size={"large"}
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                        startIcon={<ArrowBackIosRoundedIcon />}
                      >
                        Back
                      </Button>
                      {activeStep < 2 && (
                        <Button
                          className="save-btn"
                          variant="outlined"
                          size={"large"}
                          color="success"
                          sx={{ mr: 1 }}
                          onClick={() => saveSurvey(activeStep)}>
                          Save
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        size={"large"}
                        onClick={() => handleCreateNext()}
                        endIcon={<ArrowForwardIosRoundedIcon />}>
                        {activeStep === steps.length - 1 ? 'Run Survey' : 'Save and Continue'}
                      </Button>
                    </Box>
                  </React.Fragment>
                )
            }
          </div>
        </div>
      <Footer />
    </div>
  );
}

export default withAuthenticationRequired(SurveyManager, {
  onRedirecting: () => <Loading />,
});