import RightPanel from '../RightPanel';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { Container } from '@mui/material';
import Button from '@mui/material/Button';
import DynamicTable from '../DynamicTable';
import { useApi } from '../../AuthProvider';
import AddIcon from '@mui/icons-material/Add';

// tooltip
import { styled } from '@mui/material/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { tooltipClasses } from '@mui/material/Tooltip';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#202020',
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const Audiences = ({
  activeStep,
  audienceData,
  setAudienceData,
  hasAudienceData,
  isShowAudienceError,
  surveyName,
  validateSurveyName,
  hasSurveyNameError,
  validateAudience,
  currentContext,
  segmentData,
  setSegmentData,
  hasSegmentData,
  addSegment,
  addSegmentLimit,
  newSegmentName,
  hasSegmentNameError,
  newSegmentSize,
  hasSegmentSizeError,
  hasSegmentCountError,
  setNewSegmentName,
  setNewSegmentSize,
  validateSegment,
  segmentActions,
}) => {
  return (
    <Grid container>
      <Grid xs={12} md={12} lg={12} >
        <Container className="left-panel">
            <h2>Survey Name</h2>
            <p>Give your survey a unique name for later reference.</p>
            <label className='label'>Name
              <CustomTooltip placement="right"
                title={
                  <React.Fragment>
                    Name your survey with enough detail to describe its objective,
                    for example: 'Coke vs Pepsi Taste Test v1.0'<br />
                    Survey name does not affect survey results.
                  </React.Fragment>
                } >
                <em>i</em>
              </CustomTooltip>
            </label>
            <Box className="textBox" sx={{ mt: 2 }}>
              <Box className="textField-box">
                <input
                  tabIndex="1" type="text"
                  placeholder={hasSurveyNameError ? 'Please name your survey to proceed ' : 'E.g. Name Brand vs Store Brand Taste Test v1.0'}
                  name='surveyName'
                  value={surveyName}
                  className={hasSurveyNameError ? 'has-error' : ''}
                  onChange={(e) => validateSurveyName(e.target.value)}
                />
              </Box>
            </Box>
            <h2>Audience</h2>
            <p>Define the audience you want to conduct your research on.</p>
            <label className='label'>Audience
              <CustomTooltip placement="right"
                title={
                  <React.Fragment>
                    Define any group you want to target.
                    <br />You can be specific or general depending on
                    <br />your objective.
                    <br />
                    For example, 'Soda drinkers in America' or
                    <br />'Car enthusiasts in California.'
                  </React.Fragment>
                } >
                <em>i</em>
              </CustomTooltip>
            </label>
            <Box className="textBox" sx={{ mt: 2 }}>
              <Box className="textField-box">
                <input
                  tabIndex="1" type="text"
                  placeholder={isShowAudienceError || !hasAudienceData ? 'Please add your audience to proceed ' : 'Type here'}
                  name='audience'
                  value={audienceData[0]?.audience}
                  className={isShowAudienceError && !hasAudienceData ? 'has-error' : ''}
                  onChange={(e) => validateAudience(e.target.value)}
                />
              </Box>
            </Box>
          <h2>Segments</h2>
          <p>Create segments within your audience. These can be based on things like purchase or use frequency, demographics, psychographics, behaviors, or unique preferences. Each segment can be as specific as you like.</p>
          <Grid container spacing={2} justifyContent="center" alignItems="left">
            <Grid item xs={12} md={6} lg={7} className="label">
              Segment
              <CustomTooltip placement="right"
                title={
                  <React.Fragment>
                    Be as detailed as possible when describing
                    <br /> your segment. You can add multiple
                    <br />descriptors per field if needed.
                    <br />E.g People who drink Coke 1+ times per
                    <br />week AND work out 2+ times per week.
                  </React.Fragment>
                }>
                <em className="info">i</em>
              </CustomTooltip>
            </Grid>
            <Grid item xs={12} md={3} lg={2} className="label">Segment size</Grid>
            <Grid item xs={12} md={3} lg={3}></Grid>
            <Grid item xs={12} md={6} lg={7}>
              <input
                type="text"
                name="segment_name"
                value={newSegmentName}
                placeholder={hasSegmentNameError ? 'Please add a segment name' : 'Type here'}
                className={hasSegmentNameError ? 'has-error' : ''}
                onChange={(e) => setNewSegmentName(e.target.value)}
                onKeyUp={(e) => validateSegment('segment_name', e.target.value)}
              />
            </Grid>
            <Grid xs={12} md={3} lg={2}>
              <input
                type="number"
                name="segment_size"
                value={newSegmentSize}
                min="1"
                max="1000"
                placeholder={hasSegmentSizeError ? '1-1000' : 'ex: 50'}
                className={hasSegmentSizeError ? 'has-error' : ''}
                onChange={(e) => e.target.value <= 1000 ? setNewSegmentSize(e.target.value) : setNewSegmentSize(1000)}
                onKeyUp={(e) => validateSegment('segment_size', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}></Grid>
          </Grid>
          {
            segmentData.length !== addSegmentLimit &&
            <Button
              onClick={addSegment}
              variant="contained"
              startIcon={<AddIcon />}
              tabIndex={segmentData.length * 2 + 1}
              style={{ marginTop: '20px' }}
              className="crowdwave-blue"
            >
              Add segment
            </Button>
          }
          {/* <div className="improve-btn" tabIndex={segmentData.length * 2 + 2}>
                <em><img src={sparkles} alt="sparkles" /></em> Improve with AI
                </div> */}
        </Container>
        <DynamicTable
          tableData={segmentData}
          visibleColumns={['segment_name', 'segment_size']}
          editableColumns={['segment_name', 'segment_size']}
          selectOnClick={false}
          selectable={false}
          draggable={true}
          updateTableData={setSegmentData}
          actions={segmentActions}
          itemName="segment"
          rowCountError={hasSegmentCountError}
        />
      </Grid>
    </Grid>
  );
}

export default Audiences;