import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGridPro, useGridApiContext, GridCellEditStopReasons } from '@mui/x-data-grid-pro';
import InputBase from '@mui/material/InputBase';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
// Actions
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import BarChartIcon from '@mui/icons-material/BarChart';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Loading from './ui/Loading';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
    ...theme.applyStyles('dark', {
      color: theme.palette.grey[300],
    }),
  },
}));

////////////////////////////////////////
// multi-line editing cell component //
////////////////////////////////////////
function isKeyboardEvent(event) {
  return !!event.key;
}

function EditTextarea(props) {
  const { id, field, value, colDef, hasFocus } = props;
  const [valueState, setValueState] = React.useState(value);
  const [anchorEl, setAnchorEl] = React.useState();
  const [inputRef, setInputRef] = React.useState(null);
  const apiRef = useGridApiContext();

  React.useLayoutEffect(() => {
    if (hasFocus && inputRef) {
      inputRef.focus();
    }
  }, [hasFocus, inputRef]);

  const handleRef = React.useCallback((el) => {
    setAnchorEl(el);
  }, []);

  const handleChange = React.useCallback(
    (event) => {
      const newValue = event.target.value;
      setValueState(newValue);
      apiRef.current.setEditCellValue(
        { id, field, value: newValue, debounceMs: 200 },
        event,
      );
    },
    [apiRef, field, id],
  );

  return (
    <div style={{ position: 'relative', alignSelf: 'flex-start' }}>
      <div
        ref={handleRef}
        style={{
          height: 1,
          width: colDef.computedWidth,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      {anchorEl && (
        <Popper open anchorEl={anchorEl} placement="bottom-start">
          <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
            <InputBase
              multiline
              rows={4}
              value={valueState}
              sx={{ textarea: { resize: 'both' }, width: '100%' }}
              onChange={handleChange}
              inputRef={(ref) => setInputRef(ref)}
            />
          </Paper>
        </Popper>
      )}
    </div>
  );
}

const multilineColumn = {
  type: 'string',
  renderEditCell: (params) => <EditTextarea {...params} />,
};

const DynamicTable = ({
  tableData,
  visibleColumns,
  columnLabels = [],
  editableColumns = [],
  dynamicColumns = [],
  selectable,
  selectOnClick,
  draggable,
  updateTableData,
  removeable,
  actions = [],
  isCellEditable,
  itemName,
  rowCountError = false
}) => {
  // actions column
  const ActionsMenu = ({ params }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <IconButton
          id="actions-menu-toggle"
          size="small"
          aria-controls={open ? 'actions-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="outlined"
          disableElevation
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <StyledMenu
          id="actions-menu"
          MenuListProps={{
            'aria-labelledby': 'actions-menu-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {actions.map((action, index) => (
            <MenuItem key={index} onClick={() => action.label === 'Delete' ? removeRow(params, action.callback) : action.callback(params)} >
              {action.label.includes('Delete') && <DeleteIcon />}
              {action.label.includes('Copy') && <ContentCopyIcon />}
              {action.label.includes('Edit') && <EditIcon />}
              {action.label.includes('Details') && <InfoIcon />}
              {action.label.includes('Download') && <DownloadIcon />}
              {action.label.includes('Results') && <BarChartIcon />}
              {action.label}
            </MenuItem>
          ))}
        </StyledMenu>
      </div>
    );
  }


  // create list of rows from the table data prop
  const rows = tableData.map((row, index) => {
    return { id: index, ...row };
  });
  // removable / delete functionality
  if (Object.keys(actions).length > 0 && !visibleColumns.includes('actions')) {
    visibleColumns.push('actions');
  }

  const removeRow = (params, callback) => {
    const newRows = rows.filter(row => row.id !== params.row.id);
    newRows.length > 0 ? handleRowOrderChange(newRows) : updateTableData([]);
    callback(params);
  }
  // actions menu version below...
  // const removeRow = (params, callback) => {
  //   const newRows = rows.filter(row => row.id !== params.row.id);
  //   newRows.length > 0 ? handleRowOrderChange(newRows) : updateTableData([]);
  //   callback(params);
  // }

  const renderRegularCell = (params) => {
    let cellValue = params.value;
    if (params.field === 'created_at') {
      cellValue = new Date(params.value).toLocaleString();
    }
    // if the table has a list of dynamicColumns, check to see if this field is in that list
    // and if so, reveal the dynamicColumn list entry's DynamicComponent
    if (dynamicColumns.length > 0) {
      dynamicColumns.forEach((dynamicColumn) => {
        if (dynamicColumn.column === params.field) {
          cellValue = dynamicColumn.dynamicComponent(params.row);
        }
      });
    }
    return (
      <div className="dynamic-table-cell">
          {cellValue}
      </div>
    );
  };

  const renderRemoveBtn = (params) => {
    return (
      <div className="dynamic-table-cell remove">
        <IconButton onClick={() => removeRow(params.row.id)} color="error">
          <DeleteIcon />
        </IconButton>
      </div>
    );
  };

  const renderActionMenu = (params) => {
    return (
      <div className="dynamic-table-cell actions">
        <ActionsMenu params={params}/>
          { actions.map((action) => {
            Object.entries(action).map((action, index) => {
              return (
                <IconButton key={index} onClick={() => action.callback(params.row.id)} color="error">
                  {action.label}
                </IconButton>
              );
            })
          })
        }
      </div>
  )};

  // create columns from visibleColumns prop and other args
  const columns = visibleColumns.map((column, index) => {
    let columnLabel = column.replaceAll('_', ' ');
    if (columnLabels.length > 0) {
      // override the parsed key with the pretty printed label
      columnLabel = columnLabels[index];
    }
    return {
      id: index,
      field: column,
      headerName: columnLabel,
      editable: editableColumns ? editableColumns.includes(column) : false,
      resizable: column !== 'actions',
      ...multilineColumn,
      flex: index === 0 ? 2 : 1,
      renderHeader: () => (
        <div className="dynamic-table-header">
          <strong>{columnLabel}</strong>
        </div>
      ),
      renderCell: (params) => {
          if (removeable && params.field === 'remove') {
            return renderRemoveBtn(params);
          } else if (actions.length > 0 && params.field === 'actions') {
            if (params.row.status === 'running') {
               return <Loading />
            } else {
              return renderActionMenu(params);
            }
          } else {
            return renderRegularCell(params);
        }
      }
    }
  });

  // drag and drop functionality (and sort-after-delete)
  const handleRowOrderChange = (data) => {
    let tempRows = [...rows];
    let hasSortOrder = false;

    if (Array.isArray(data)) {
      // rows can only be dragged one at a time and it passes an object,
      // so we know if we're passed an array it's been passed by removeRow,
      // and the array should just be the new table rows.
      hasSortOrder = data[0].hasOwnProperty('sort_order');
      tempRows = data;
    } else {
      // if we're passed an object, we need to update the table rows
      // and sort them based on the new order
      hasSortOrder = data.row.hasOwnProperty('sort_order');
      if (data.oldIndex < 0 || data.targetIndex < 0 || data.oldIndex === data.targetIndex) {
        return;
      } else if (data.targetIndex === tempRows.length - 1) {
        // moving the item to the end of the list
        tempRows.splice(data.oldIndex, 1);
        tempRows.push(data.row);
      } else {
        tempRows.splice(data.oldIndex, 1);
        tempRows.splice(data.targetIndex, 0, data.row);
      }
    }

    // now that the table is sorted, check for item sort_order and apply table index if found
    if (hasSortOrder) {
      tempRows = tempRows.map((row, index) => {
        return { ...row, sort_order: index + 1 };
      });
      updateTableData(tempRows);
    } else {
      updateTableData(tempRows);
    }
  }

  // editable cell functionality
  const processRowUpdate = (newRow) => {
    // TODO: fix for question parsing, needs refined
    // check to see if the "raw_options" key is present in the newRow object
    // and if so, update the "options" key with the value of "raw_options" split into a new array
    if (newRow.raw_options) {
      newRow.options = newRow.raw_options.split(',');
    }
    const updatedRow = { ...newRow, isNew: false };
    updateTableData(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  // custom error state for no rows on attempted submit / next, we add a conditional "no rows" overlay
  const CustomNoRowsOverlay = () => {
    return (
      <div className="no-rows-overlay">
        {rowCountError ? <h4 style={{ color: 'red', margin: '20px 0', textAlign: 'center'}}>Error: You must add at least one {itemName}</h4> : <h4 style={{ margin: '20px 0', textAlign: 'center'}}>No {itemName}s added</h4>}
      </div>
    );
  };

  return (
    <Box className="dynamic-table-container">
      {/* { removable && <Button onClick={() => removeRow()} variant="contained" startIcon={<DeleteIcon />} style={{ marginBottom: "8px" }} className="crowdwave-blue">Remove selected row</Button> } */}
      <DataGridPro
        rows={rows}
        columns={columns}
        loading={false}
        rowHeight={38}
        checkboxSelection={selectable}
        disableRowSelectionOnClick={selectOnClick}
        rowReordering={draggable}
        onRowOrderChange={handleRowOrderChange}
        sx={{ border: 'none' }}
        processRowUpdate={processRowUpdate}
        getRowHeight={() => 'auto'}
        isCellEditable={isCellEditable}
        onCellEditStop={(params, event) => {
          if (params.reason !== GridCellEditStopReasons.enterKeyDown) {
            return;
          }
          if (isKeyboardEvent(event) && !event.ctrlKey && !event.metaKey) {
            event.defaultMuiPrevented = true;
          }
        }}
        autoHeight
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
      />
    </Box>
  );
}

export default DynamicTable;